:root {
  --prussian-blue-pb-900: #002a5b;
  --prussian-blue-pb-800: #073b72;
  --prussian-blue-pb-700: #10447f;
  --prussian-blue-pb-600: #1c4e8a;
  --prussian-blue-pb-500: #245693;
  --prussian-blue-pb-400: #4b6ea1;
  --prussian-blue-pb-300: #6c87b0;
  --prussian-blue-pb-200: #95a9c7;
  --prussian-blue-pb-100: #bfcade;
  --prussian-blue-pb-050: #e6eaf0;
  --neon-blue-nb-900: #0000b6;
  --neon-blue-nb-800: #0017cf;
  --neon-blue-nb-700: #0027da;
  --neon-blue-nb-600: #1634e6;
  --neon-blue-nb-500: #1b3df2;
  --neon-blue-nb-400: #4d5ef7;
  --neon-blue-nb-300: #727df9;
  --neon-blue-nb-200: #9ea2fa;
  --neon-blue-nb-100: #c6c7fc;
  --neon-blue-nb-050: #e9e9fe;
  --neon-blue-nb-025: #f8f8fe;
  --bright-turquoise-bt-900: #006d6f;
  --bright-turquoise-bt-800: #00919f;
  --bright-turquoise-bt-700: #00a6b8;
  --bright-turquoise-bt-600: #00bbd4;
  --bright-turquoise-bt-500: #00cce8;
  --bright-turquoise-bt-400: #00d5eb;
  --bright-turquoise-bt-300: #00dff0;
  --bright-turquoise-bt-200: #19ebf5;
  --bright-turquoise-bt-100: #98f2f8;
  --bright-turquoise-bt-050: #d7fafc;
  --gold-gd-900: #f67300;
  --gold-gd-800: #f89f00;
  --gold-gd-700: #f9b800;
  --gold-gd-600: #fad200;
  --gold-gd-500: #f8e100;
  --gold-gd-400: #fbe63f;
  --gold-gd-300: #fceb66;
  --gold-gd-200: #fdf193;
  --gold-gd-100: #fef7be;
  --gold-gd-050: #fffce5;
  --torch-red-tr-900: #c40003;
  --torch-red-tr-800: #d20014;
  --torch-red-tr-700: #de001d;
  --torch-red-tr-600: #f00022;
  --torch-red-tr-500: #ff151f;
  --torch-red-tr-400: #fa3941;
  --torch-red-tr-300: #f06568;
  --torch-red-tr-200: #f79293;
  --torch-red-tr-100: #ffc9cf;
  --torch-red-tr-050: #ffe9ed;
  --dodger-blue-db-900: #0d47a1;
  --dodger-blue-db-800: #1565c0;
  --dodger-blue-db-700: #1976d2;
  --dodger-blue-db-600: #1e88e5;
  --dodger-blue-db-500: #2196f3;
  --dodger-blue-db-400: #42a5f5;
  --dodger-blue-db-300: #64b5f6;
  --dodger-blue-db-200: #90caf9;
  --dodger-blue-db-100: #bbdefb;
  --dodger-blue-db-050: #e3f2fd;
  --lime-green-lg-900: #007209;
  --lime-green-lg-800: #009420;
  --lime-green-lg-700: #1ea92b;
  --lime-green-lg-600: #3dbd35;
  --lime-green-lg-500: #4ece3d;
  --lime-green-lg-400: #6ed65c;
  --lime-green-lg-300: #8cde7b;
  --lime-green-lg-200: #afe8a2;
  --lime-green-lg-100: #cff1c7;
  --lime-green-lg-050: #ecfae9;
  --white-wh-900: #fff;
  --grey-gr-900: #111827;
  --grey-gr-800: #1f2937;
  --grey-gr-700: #374151;
  --grey-gr-600: #4b5563;
  --grey-gr-500: #6b7280;
  --grey-gr-400: #9ca3af;
  --grey-gr-300: #d1d5db;
  --grey-gr-200: #e5e7eb;
  --grey-gr-100: #f3f4f6;
  --grey-gr-050: #f9fafb;
}

* {
  scrollbar-width: auto;
  scrollbar-color: var(--grey-gr-300) #ffffff;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--grey-gr-300);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

body {
  background-color: #f8f8fe;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter;
  font-size: 14px;
  margin: 0;
  min-width: 316px;
  overflow-y: scroll;
}

.klaro-appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: top 800ms ease-in-out !important;
}

.klaro-appbar_logocontainer {
  display: flex;
  cursor: pointer;
}

.klaro-appbar_logo_demo {
  color: var(--bright-turquoise-bt-100);
  text-transform: lowercase;
  font-size: 17px;
  width: 80px;
  line-height: 15px;
}

.klaro-theme_v1 .klaro-appbar {
  background-color: #f8f8fe;
}

.klaro-theme_v2 .klaro-appbar {
  box-shadow: none;
}

.klaro-theme_v2 .klaro-appbar .MuiTabs-indicator {
  background-color: var(--bright-turquoise-bt-100);
  height: 4px;
}

.klaro-theme_v2 .klaro-appbar .MuiTab-root {
  color: var(--white-wh-900) !important;
  text-transform: none !important;
  font-size: 14px !important;
  border-bottom: 4px solid var(--neon-blue-nb-400);
  padding: 14px 12px 12px 12px;
  transition: border-bottom 200ms ease-in-out !important;
}

.klaro-appbar .MuiTab-root:hover {
  border-bottom-color: var(--neon-blue-nb-100);
}

.klaro-appbar .MuiTab-root:focus {
  /*outline:2px solid white*/
}

.klaro-theme_v2 .klaro-appbar_logo {
  cursor: pointer;
}

.klaro-theme_v2 .klaro-appbar_logo path {
  fill: var(--neon-blue-nb-500);
}

.klaro-theme_v2 .klayo-iconbutton path[fill="#98F2F8"] {
  fill: #9eadba;
}

.klayo_profilepic {
  font-family: Inter;
  font-weight: 500;
  border-radius: 50%;
  text-transform: uppercase;
  display: flex;
}

.klayo_profilepic_compliantbadge,
.klayo_profilepic_noncompliantbadge {
  width: 24px;
  height: 24px;
  margin: 0 16px 16px 0;
}

.klayo_profilepic-initials {
  font-family: Inter;
  font-weight: 300;
}

.klaro-appbar_toolbar {
  width: 100%;
}

.klaro-appbar_logo {
  margin-right: 22px;
}

.klayo-appdrawer_logo {
  cursor: pointer;
}

.klaro-appbar_nav {
  height: 64px;
}

.klaro-appbar_nav-tab {
  height: 64px;
}

.klaro-appbar_nav-tab--hidden:not(.active) {
  width: 0px !important;
  min-width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.klaro-appbar_menubutton {
  max-width: 32px;
  margin-right: 16px !important;
}

.klaro-appbar_menubutton svg path[fill="#1B3DF2"] {
  fill: white !important;
}

.klayo-main {
}

.klayo-main h1 {
  padding: 0;
  margin: 0 0 40px 0;
  font-family: Inter;
  font-size: 30px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 36px;
  letter-spacing: -1px;
  color: var(--grey-gr-900);
}

.klayo-main h2 {
  padding: 0px;
  margin: 0px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.3px;
  color: var(--grey-gr-900);
}

.klayo-main--fullwidth {
  max-width: none !important;
}

.klaro-profile_subpage-nav {
  margin: 30px 0;
}

.klaro_profile-overview {
  padding: 32px;
}

.klaro_profile-overview_basic {
  display: flex;
}

.klaro_profile-jobtitle {
  margin-top: 10px;
}

.klaro_profile-email {
  display: block;
  color: #4b5563;
  text-decoration: none;
}

.klaro_profile-overview_basic h2 {
  padding: 0;
  margin: 10px 0 10px 0;
  font-size: 30px;
  color: #4b5563;
}

.klayo_profilepic-container {
  margin: 0 32px 32px 0;
}

.klayo_profilepic-edit {
  border: 1px solid #00000042 !important;
  background-color: white !important;
}

.klaro_profile-overview_detailed {
}

.klaro_profile-overview_detailed .MuiGrid-item > div {
  padding: 0px 20px 10px 0;
}

.klaro_profile-overview_detailed label {
  font-weight: 500;
  min-width: 150px;
  display: inline-block;
}

.klaro_profile-jobdesc {
  padding: 30px;
  font-family: Inter;
  line-height: 24px;
  font-size: 16px;
  color: var(--grey-gr-700);
}

.klaro_profile-jobdesc a {
  padding-left: 16px;
  text-decoration: none;
  font-size: 14px;
}

.klaro_section {
  padding: 30px;
}

.klaro_section h4 {
  margin: 0 0 22px 0;
  padding: 0;
  color: #4b5563;
  font-weight: 500;
}

.klaro_attrtable {
  border: none !important;
}

.klaro_attrtable_clickable .MuiDataGrid-row {
  cursor: pointer;
}

.klaro_attr-progress {
}

.klaro_attr-progress_label {
  display: flex;
  height: 20px;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
  color: var(--grey-gr-900) !important;
  width: 100%;
}

.klaro_attr-progress_label:not(.klaro_attr-progress_labelsinglerow) {
  padding-bottom: 12px;
}

.klaro_attr-progress_name {
  margin-right: 10px;
}

.klaro_attr-progress_fractionlabel {
  font-weight: 600;
  white-space: nowrap;
}

.klaro_attr-progress_percentlabel {
  padding-left: 10px;
  white-space: nowrap;
}

.klayo_bubblechart {
  position: relative;
}

.klayo_bubblechart_bubble_inner {
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
}

.klayo_bubblechart circle:hover {
  transition: all 200ms ease-in;
}

.klayo_bubblechart circle:hover {
  opacity: 1 !important;
}

.klayo_bubblechart_bubblelabel {
  text-overflow: ellipsis;
  overflow: hidden;
}

.klayo_bubblechart_bubble30 .klayo_bubblechart_bubblelabel {
  font-weight: 200;
  letter-spacing: -1px;
  opacity: 0.9;
}

.klayo_bubblechart_bubble40 .klayo_bubblechart_bubblelabel {
  font-weight: 300;
  letter-spacing: -0.8px;
}

.klayo_bubblechart_bubble50 .klayo_bubblechart_bubblelabel {
  font-weight: 300;
  letter-spacing: -0.8px;
}

.klayo_bubblechart_bubble60 .klayo_bubblechart_bubblelabel {
  font-weight: 600;
}

.klayo_bubblechart_bubble70 .klayo_bubblechart_bubblelabel {
  font-weight: 600;
}

.klayo_bubblechart_bubble80 .klayo_bubblechart_bubblelabel {
  font-weight: 600;
}

.klayo_bubblechart_tooltip {
  position: fixed;
  pointer-events: none;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
  min-width: 150px;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  border: solid 1px var(--neon-blue-nb-200) !important;
  background-color: var(--white-wh-900) !important;
}

.klayo_bubblechart_tooltip {
  padding: 8px 14px !important;
}

.klayo_bubblechart_tooltip_employees {
  font-size: 12px;
  color: #999;
}

.klaro_attr-progress_label label {
  font-weight: 700;
}

#klaro-appbar_userbutton {
  width: 32px;
  height: 32px;
}

.klaro_team-filterbutton .MuiButton-startIcon,
#klaro-appbar_userbutton svg {
  pointer-events: none;
}

.klaro_team-filterbutton svg path[fill="#1B3DF2"] {
  fill: white !important;
}

.klaro-appbar_usermenu .MuiPaper-root {
  min-width: 250px;
  max-width: 100%;
  left: 12px !important;
}

.klaro_feedback-table_reviewlink {
  color: var(--neon-blue-nb-500) !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.klaro_userswitch_table {
  border: none !important;
}

.klaro_userswitch_table .MuiDataGrid-row {
  cursor: pointer;
  border: none !important;
}

.klaro_userswitch_table .MuiDataGrid-cell,
.klaro_userswitch_table .MuiDataGrid-columnsContainer {
  border: none !important;
}

.klayo_proficiencyselector_original {
  display: flex;
}

.klayo_proficiencyselector_original > div {
  width: 44px;
  text-align: center;
}

.klayo_proficiencyselector .MuiToggleButtonGroup-root {
  height: 42px;
}

.klayo_proficiencyselector .MuiButtonBase-root {
  border-radius: 21px;
  width: 45px;
  border-color: var(--neon-blue-nb-100) !important;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  text-align: center;
  color: var(--grey-gr-900);
}

.klayo_proficiencyselector .MuiButtonBase-root:hover {
  background-color: var(--neon-blue-nb-050) !important;
}

.klayo_proficiencyselector .MuiButtonBase-root.Mui-selected {
  background-color: var(--neon-blue-nb-100) !important;
  color: var(--grey-gr-900);
}

.klayo_proficiencyselector .MuiButtonBase-root.Mui-disabled {
  background-color: var(--white-wh-900) !important;
  border: solid 1px var(--grey-gr-300) !important;
}

.klayo_proficiencyselector .MuiButtonBase-root.Mui-selected.Mui-disabled {
  background-color: var(--grey-gr-100) !important;
}

.klayo_proficiencyselector--error .MuiButtonBase-root {
  border-color: var(--torch-red-tr-600) red !important;
}

.klayo_proficiencyselector--error .MuiInputLabel-root {
  color: var(--torch-red-tr-600);
}

.klaro_attrppl_table {
  border: none !important;
}

.klaro_team-attr-table,
.klaro_team-gap-table,
.klaro_team-table,
.klayo-table_grid {
  border: none !important;
}

.klaro_team-attr-table .MuiDataGrid-row,
.klaro_team-gap-table .MuiDataGrid-row,
.klaro_team-table .MuiDataGrid-row {
  cursor: pointer;
}

.klaro_employeeprofile-appbar {
  border-bottom: 1px solid #e9e9fe;
  background-color: #f8f8fe !important;
}

.klaro_employeeprofile .klayo-loadingbar {
  position: fixed;
}

.klaro_employeeprofile .klaro-appbar_logo {
  height: 32px;
}

.klaro_employeeprofile .klaro-appbar_logo svg path {
  fill: var(--neon-blue-nb-400);
}

.klayo-billingplandialog-appbar .klaro-appbar_logo {
  width: 89px;
  height: 32px;
}

.klayo-billingplandialog-appbar .klaro-appbar_logo path {
  fill: var(--neon-blue-nb-400);
}

.klayo-appdrawer_logo {
  width: 78.17px;
  height: 28px;
}

.klayo-appdrawer_logo path {
  fill: var(--neon-blue-nb-500);
}

.klayo_attributebubbles {
}

.klayo_attributebubbles_bubble {
  width: 1px;
  height: 1px;
  cursor: pointer;
  transition: all 200ms ease-in;
}

.klayo_attributebubbles_bubble:hover {
  transform: scale(1.2);
}

.klaro_notificationlist {
  border: none !important;
}

.klaro_notificationlist .MuiDataGrid-row {
  cursor: pointer;
  border: none !important;
  font-family: Inter !important;
}

.klaro_notificationlist_primarytext {
  line-height: 24px !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--grey-gr-900);
  letter-spacing: -0.1px;
}

.klaro_notificationlist_secondarytext {
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: Inter !important;
  font-weight: 400 !important;
  color: var(--grey-gr-500);
  letter-spacing: -0.1px;
}

.klaro_notificationlist_date {
  line-height: 15px;
}

.klayo-notificationdrawer .klayo-placeholder_icon svg path[fill="#98F2F8"] {
  fill: var(--neon-blue-nb-200);
}

.klaro_notificationlist_date {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--grey-gr-500) !important;
}

.MuiDataGrid-row.highlight {
  background-color: #4d5ef7;
  color: white;
  transition: all 1s ease-in;
}

.klayo_peoplecard {
  border-radius: 16px !important;
  border: solid 1px var(--neon-blue-nb-050) !important;
  background-color: var(--white-wh-900) !important;
  box-shadow: none !important;
  min-height: 286px;
  transition: box-shadow 200ms ease-in-out;
}

.klayo_peoplecard:hover {
  box-shadow:
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.klayo_peoplecard_name {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.3px;
  color: var(--grey-gr-900);
  text-align: center;
  width: 100%;
  white-space: nowrap;
}

.klaro_peoplecard_fake--row .klayo_peoplecard_name {
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 500;
}

.klayo_peoplecard_job {
  font-family: Inter;
  font-size: 16px;
  letter-spacing: -0.1px;
  color: var(--grey-gr-500);
  text-align: center;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.klaro_peoplecard_fake--row .klayo_peoplecard_job {
  letter-spacing: 0;
  font-size: 14px;
}

.klayo_peoplecard_attrs {
  font-family: Inter;
  font-size: 16px;
}

.klaro_peoplecard_fake--row .klayo_peoplecard_attrs {
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.klaro_peoplecard_fake--row .klayo_peoplecard_attrs b {
  font-weight: 400;
  color: var(--grey-gr-900) !important;
}

.klayo_peoplecard_gaps {
  font-family: Inter;
  font-size: 16px;
}

.klaro_peoplecard_fake--row .klayo_peoplecard_gaps {
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.klaro_peoplecard_fake--row .klayo_peoplecard_gaps b {
  font-weight: 400;
  color: var(--grey-gr-900) !important;
}

.klayo_peoplecard .MuiCardContent-root {
  padding: 25px !important;
  position: relative !important;
}

.klaro_teampeople {
  transition: opacity 600ms ease-in-out;
}

.klayo_teampeople_rowcount {
  font-family: Inter;
  font-size: 16px;
  letter-spacing: -0.1px;
}

.klaro_peoplecard_fake {
  position: absolute;
  width: 100px;
  height: 100px;
  background: white;
  z-index: 9999;
  border: 1px solid white;
  transition: opacity 600ms ease-in-out;
  box-shadow: none;
  pointer-events: none;
}

.klaro_peoplecard_fake--moving {
}

.klayo_peoplecard .MuiCardContent-root > * {
  position: absolute !important;
}

.klaro_peoplecard_fake--moving,
.klaro_peoplecard_fake--moving .MuiCardContent-root > * {
  transition:
    all 400ms ease-in-out,
    opacity 0ms !important;
}

.klaro_peoplecard_fake--hidden {
  opacity: 0;
}

.klaro_peoplecard_fake--card {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  border: none;
}

.klaro_peoplecard_fake--row {
  border-radius: 0px !important;
  border-color: transparent !important;
  max-height: 60px !important;
  min-height: 0 !important;
  margin-top: 1px;
}

.klaro_peoplecard_fake--row .MuiCardContent-root {
  padding: 16px !important;
}

.klayo-notloggedinview,
.klayo-logginginview {
  background-color: #f8f8fe;
  height: 100vh;
  align-items: center;
  display: flex;
}

.klayo-notloggedinview h1,
.klayo-logginginview h1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.klayo-notloggedinview svg path,
.klayo-logginginview svg path {
  fill: var(--neon-blue-nb-400);
}

.klayo_employeetable_name {
  color: var(--grey-gr-900);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
}

.klayo_employeetable_picjobname .klayo_employeetable_name {
  line-height: 20px;
}

.klayo_employeetable_job {
  color: var(--grey-gr-500);
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
}

.klayo_employeetable_pic .klayo_profilepic {
  margin-right: 12px;
  border: solid 2px var(--white-wh-900);
}

.klayo_employeetable_pic {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.klayo-table {
  border-radius: 16px !important;
  box-shadow: none !important;
  background-color: var(--white-wh-900) !important;
}

.klayo-table.klayo-table--withheader .MuiDataGrid-columnHeaders {
  border-bottom-color: var(--grey-gr-400) !important;
}

.klayo-table:not(.klayo-table--withheader) .MuiDataGrid-columnHeaders {
  border: none !important;
}

.klayo-table_grid .MuiDataGrid-cell {
  border-bottom-color: var(--grey-gr-200) !important;
  color: var(--grey-gr-900) !important;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0 !important;
}

.klayo-table--hidefirstlastborder .MuiDataGrid-row:last-child .MuiDataGrid-cell {
  border: none !important;
}

.klayo-table_grid .MuiDataGrid-row:hover {
  background-color: var(--grey-gr-100);
}

.klayo-table_grid .MuiDataGrid-columnHeaderTitle,
.klayo-table_headerwrap {
  color: var(--grey-gr-600) !important;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.klayo-paper {
  border-radius: 16px;
  box-shadow: none !important;
  border: solid 1px var(--neon-blue-nb-050);
  background-color: var(--white-wh-900) !important;
  overflow: hidden;
}

.klayo-paper_title {
  font-family: Inter !important;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px !important;
  letter-spacing: -0.3px;
  color: var(--grey-gr-900) !important;
}

.klayo-buttongroup {
  height: 44px;
  border-radius: 22px !important;
  border: solid 1px var(--neon-blue-nb-100);
  background-color: var(--white-wh-900);
  overflow: hidden;
}

.klayo-buttongroup .MuiToggleButton-root {
  width: 44px;
  height: 44px;
  border: none;
}

.klayo-buttongroup .MuiToggleButton-root:hover {
  background-color: var(--neon-blue-nb-050) !important;
}

.klayo-buttongroup .MuiToggleButton-root.Mui-selected {
  background-color: var(--neon-blue-nb-100) !important;
}

.klayo-togglebutton {
  height: 40px;
  padding: 4px;
  border-radius: 20px !important;
  background-color: var(--neon-blue-nb-050);
  box-sizing: border-box;
}

.klayo-togglebutton:hover {
  background-color: var(--neon-blue-nb-100);
}

.klayo-togglebutton .MuiToggleButton-root {
  width: 32px;
  height: 32px;
  padding: 5px;
  border-radius: 16px !important;
  border: none !important;
  color: var(--grey-gr-600);
}

.klayo-togglebutton .MuiToggleButton-root:hover {
  background-color: var(--neon-blue-nb-100);
}

.klayo-togglebutton .MuiToggleButton-root.Mui-selected {
  border: solid 1px var(--neon-blue-nb-100) !important;
  background-color: var(--white-wh-900) !important;
  color: var(--neon-blue-nb-500);
}

.klayo-togglebutton svg path[fill="#1B3DF2"],
.klayo-togglebutton svg path[fill="#4B5563"] {
  fill: var(--grey-gr-600);
}

.klayo-togglebutton .MuiToggleButton-root.Mui-selected svg path[fill="#4B5563"],
.klayo-togglebutton .MuiToggleButton-root.Mui-selected svg path[fill="#1B3DF2"] {
  fill: var(--neon-blue-nb-500);
}

.klayo-togglebutton--disabled {
  background-color: var(--grey-gr-200) !important;
}

.klayo-togglebutton--disabled .MuiToggleButton-root.Mui-selected {
  border: solid 1px var(--grey-gr-300) !important;
  background-color: var(--white-wh-900) !important;
}

.klayo-togglebutton--disabled svg path[fill="#1B3DF2"],
.klayo-togglebutton--disabled svg path[fill="#4B5563"] {
  fill: var(--grey-gr-400) !important;
}

.klayo-pilltabs {
  height: 64px;
  border-radius: 32px;
  background-color: var(--neon-blue-nb-050);
}

.klayo-pilltabs_tabs {
}

.klayo-pilltabs_menu .MuiListItemText-root {
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: -0.1px !important;
  color: var(--grey-gr-600) !important;
}

.klayo-pilltabs_tabs .MuiTabs-scroller {
  padding: 8px;
}

.klayo-pilltabs .MuiTabs-indicator {
  bottom: 8px;
  z-index: 0;
  border-radius: 24px;
  border: solid 1px var(--neon-blue-nb-100);
  background-color: var(--white-wh-900);
}

.klayo-pilltabs .MuiTab-root {
  z-index: 1;
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: -0.1px !important;
  text-align: center !important;
  color: var(--grey-gr-600) !important;
  text-transform: none;
  margin-right: 18px;
  border-radius: 24px;
  transition: background-color 200ms ease-in-out;
}

.klayo-pilltabs .MuiTab-root:hover {
  background-color: var(--neon-blue-nb-100) !important;
}

.klayo-pilltabs .MuiTab-root.active {
  color: var(--grey-gr-900) !important;
  background-color: transparent !important;
}

.klayo-pilltabs .MuiTab-root.Mui-disabled {
  opacity: 0.5;
}

.klayo-pilltabs_tabs_dropbutton {
}

.klayo-pilltabs_tabs_dropbutton svg {
  pointer-events: none;
}

.klayo-pilltabs_tabs_dropbutton svg path[fill="#9CA3AF"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klayo-button {
  height: 48px;
  line-height: 48px !important;
  border-radius: 24px !important;
  text-transform: none !important;
  box-shadow: none !important;
  transition: background-color 100ms ease-in-out !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  padding: 11px 23px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.klayo-button::before {
  content: "";
  position: absolute;
}

.klayo-button-sm {
  height: 32px;
  border-radius: 16px !important;
}

.klayo-button-sm:not(.klayo-iconbutton) {
  line-height: 20px !important;
  padding: 5px 11px !important;
}

.klayo-button-md {
  height: 40px;
  border-radius: 20px !important;
}

.klayo-button-md:not(.klayo-iconbutton) {
  line-height: 40px !important;
  font-size: 14px !important;
  padding: 9px 15px 9px 15px;
}

.klayo-button-outlined {
  border: solid 1px var(--neon-blue-nb-100) !important;
  color: var(--grey-gr-900) !important;
}

.klayo-button-filled {
  color: var(--white-wh-900) !important;
}

.klayo-button-filled-danger {
  background-color: var(--torch-red-tr-500) !important;
  color: var(--white-wh-900) !important;
}

.klayo-button-filled-danger .klayo-button_label {
  color: var(--white-wh-900) !important;
}

.klayo-button-filled-danger:hover {
  background-color: var(--torch-red-tr-700) !important;
  color: var(--white-wh-900) !important;
}

.klayo-button-filled-warning {
  background-color: var(--gold-gd-700) !important;
  color: var(--white-wh-900) !important;
}

.klayo-button-filled-warning .klayo-button_label {
  color: var(--white-wh-900) !important;
}

.klayo-button-filled-warning:hover {
  background-color: var(--gold-gd-800) !important;
  color: var(--white-wh-900) !important;
}

.klayo-button-outlined.Mui-disabled {
  background-color: transparent !important;
  color: var(--grey-gr-400) !important;
  border-color: var(--grey-gr-200) !important;
}

.klayo-button-outlined.Mui-disabled .klayo-button_label {
  color: var(--grey-gr-400) !important;
}

.klayo-button-filled.Mui-disabled {
  background-color: var(--grey-gr-200) !important;
  color: var(--grey-gr-400) !important;
}

.klayo-button-outlined.Mui-disabled svg path[fill="#111827"],
.klayo-button-outlined.Mui-disabled svg path[fill="#727DF9"] {
  fill: var(--grey-gr-400) !important;
}

.MuiMenu-paper,
.MuiAutocomplete-popper {
  padding: 15px 0 16px;
  border-radius: 16px !important;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  border: solid 1px var(--neon-blue-nb-200) !important;
  background-color: var(--white-wh-900) !important;
  margin-top: 4px !important;
  overflow: hidden;
}

.klayo-selectmenu_list-hasgroups .MuiMenuItem-root {
  padding: 8px 15px 8px 20px;
}

.klayo-selectmenu_group {
  color: var(--grey-gr-400) !important;
  text-transform: uppercase !important;
  font-size: 13px !important;
  line-height: 24px !important;
}

.MuiAutocomplete-popper .MuiPaper-root {
  border-radius: 0;
  box-shadow: none;
}

.MuiMenu-paper ul,
.MuiAutocomplete-popper ul {
  margin: 0 !important;
  padding: 0 !important;
}

.MuiMenu-paper li,
.MuiAutocomplete-popper li,
.klaro-appmenu_menuitem {
  height: 40px;
  padding: 8px 15px 8px 15px;
  background-color: var(--white-wh-900);
}

.klaro-appmenu_menuitem {
  min-width: 200px;
}

.MuiMenu-paper li,
.klaro-appmenu_menuitem,
.MuiAutocomplete-popper li {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap;
}

.MuiMenu-paper li,
.MuiMenu-paper li .MuiListItemText-primary,
.MuiAutocomplete-popper li,
.klaro-appmenu_menuitem,
.MuiAutocomplete-popper li .MuiListItemText-primary {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: -0.1px !important;
  color: var(--grey-gr-900);
  cursor: pointer;
}

.MuiMenu-paper li p,
.klaro-appmenu_menuitem .MuiListItemText-root {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding: 0;
  letter-spacing: -0.1px !important;
  color: var(--grey-gr-900);
}

.MuiMenu-paper li svg path[fill="#111827"],
.MuiMenu-paper li svg path[fill="#9EA2FA"],
.MuiAutocomplete-popper li svg path[fill="#111827"],
.MuiMenu-paper li svg path[fill="#727DF9"],
.klaro-appmenu_menuitem svg path[fill="#111827"],
.klaro-appmenu_menuitem svg path[fill="#727DF9"],
.klaro-appmenu_menuitem svg path[fill="#9EA2FA"] {
  fill: var(--neon-blue-nb-500) !important;
}

.MuiMenu-paper li svg,
.klaro-appmenu_menuitem svg {
  width: 24px;
  height: 24px;
}

.klayo-iconbutton {
  box-sizing: content-box !important;
}

.klayo-iconbutton.klayo-button-sm {
  width: 24px;
  height: 24px;
  padding: 4px;
}

.klayo-iconbutton.klayo-button-md {
  width: 20px;
  height: 20px;
  padding: 6px;
}

.klayo-iconbutton.klayo-button-lg {
  width: 24px;
  height: 24px;
  padding: 8px;
}

.klayo-button svg path[fill="#111827"],
.klayo-button svg path[fill="#9CA3AF"] {
  fill: var(--neon-blue-nb-500);
}

.klayo-button.Mui-disabled svg path[fill="#111827"],
.klayo-button.Mui-disabled svg path[fill="#9CA3AF"] {
  fill: var(--grey-gr-400) !important;
}

.klayo-button-filled svg path[fill="#111827"] {
  fill: var(--white-wh-900);
}

.MuiMenu-paper li:hover,
.MuiAutocomplete-popper li:hover,
.MuiMenu-paper li:hover p,
.klaro-appmenu_menuitem:hover {
  background-color: var(--neon-blue-nb-500) !important;
  color: var(--white-wh-900) !important;
}

.MuiMenu-paper li:hover .MuiListItemText-primary,
.MuiAutocomplete-popper li:hover .MuiListItemText-primary,
.klaro-appmenu_menuitem:hover .MuiListItemText-root {
  color: var(--white-wh-900) !important;
}

.MuiMenu-paper li:hover svg path[fill="#111827"],
.MuiMenu-paper li:hover svg path[fill="#9EA2FA"],
.MuiAutocomplete-popper li:hover svg path[fill="#111827"],
.MuiMenu-paper li:hover svg path[fill="#727DF9"],
.MuiMenu-paper li:hover svg path[fill="#1B3DF2"],
.klaro-appmenu_menuitem:hover svg path[fill="#111827"],
.klaro-appmenu_menuitem:hover svg path[fill="#727DF9"],
.klaro-appmenu_menuitem:hover svg path[fill="#1B3DF2"],
.klaro-appmenu_menuitem:hover svg path[fill="#9EA2FA"] {
  fill: var(--white-wh-900) !important;
}

.MuiAutocomplete-popper li[aria-selected="true"] {
  background-color: var(--neon-blue-nb-500) !important;
  color: var(--white-wh-900) !important;
}

.MuiMenu-paper li.Mui-selected .MuiListItemText-primary,
.MuiAutocomplete-popper li.Mui-selected .MuiListItemText-primary {
  font-weight: 600 !important;
}

.klayo-attrtable_warningicon path[fill="#111827"] {
  fill: var(--torch-red-tr-600) !important;
}

.klayo-attrtable_checkicon path[fill="#111827"] {
  fill: var(--lime-green-lg-700) !important;
}

.klayo-attrtable_pendingicon path[fill="#111827"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klayo-devcomponents .MuiDialogContent-root {
  background-color: #f8f8fe !important;
  color: var(--neon-blue-nb-500);
}

.klayo-devcomponents_appbar {
  background-color: #f8f8fe !important;
}

.klayo-devcomponents_appbar .MuiTypography-root {
  font-family: Inter !important;
  font-size: 30px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1px !important;
  color: var(--grey-gr-900) !important;
}

.klayo-devcomponents_appbar .MuiIconButton-root path[fill="#111827"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klayo-textfield {
  padding: 9px 13px 9px 13px;
}

.klayo-textfield .MuiOutlinedInput-root {
  padding: 11px 14px !important;
  background-color: var(--white-wh-900);
  border-radius: 21px;
}

.klayo-textfield-dense {
  border-radius: 20px;
}

.klayo-textfield-dense .MuiOutlinedInput-root {
  height: 40px !important;
  padding-right: 6px !important;
  border-radius: 20px;
}

.klayo-textfield .MuiOutlinedInput-root.Mui-disabled {
  background-color: var(--grey-gr-100) !important;
}

.klayo-textfield .MuiOutlinedInput-root.Mui-disabled fieldset {
  border: solid 1px var(--grey-gr-300) !important;
}

.klayo-textfield .MuiOutlinedInput-root.Mui-disabled path[fill="#1B3DF2"] {
  fill: var(--grey-gr-400) !important;
}

.klayo-textfield .MuiTextField-root {
  background-color: var(--white-wh-900);
}

.klayo-textfield .MuiOutlinedInput-root fieldset {
  border-radius: 21px;
}

.klayo-textfield .MuiOutlinedInput-root.Mui-error fieldset {
  border: 2px solid var(--torch-red-tr-600) !important;
}

.klayo-textfield
  .MuiOutlinedInput-root.Mui-error
  .klayo-selectmenu_dropbutton
  path[fill="#1B3DF2"] {
  fill: var(--torch-red-tr-400) !important;
}

.klayo_date-picker .MuiOutlinedInput-root.Mui-error fieldset {
  border: 2px solid var(--torch-red-tr-600) !important;
}

.klayo_date-picker .date-picker-label-error {
  color: var(--torch-red-tr-400) !important;
}

.klayo-selector .MuiOutlinedInput-root {
  padding: 12px 14px !important;
}

.klayo-selector .MuiOutlinedInput-root fieldset {
  margin-top: 1px;
  overflow: visible;
  z-index: 2;
}

.klayo-selector .MuiOutlinedInput-root:before {
  content: "";
  background: linear-gradient(90deg, #ffffff 30%, #ffffff00);
  bottom: 1px;
  content: "";
  width: 20px;
  position: absolute;
  left: -2px;
  top: 7px;
  margin-top: -4px;
  bottom: 3px;
  z-index: 1;
}

.klayo-textfield-dense .MuiOutlinedInput-root fieldset {
  border-radius: 20px;
}

.klayo-textfield input,
.klayo-textfield textarea {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--grey-gr-900);
  height: 20px;
  line-height: 20px;
  padding: 0 !important;
}

.klayo-textfield-dense input {
  font-size: 14px;
  height: 20px;
  line-height: 20px;
}

.klayo-textfield input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

.klayo-textfield input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

@keyframes onAutoFillStart {
  from {
    /**/
  }

  to {
    /**/
  }
}

@keyframes onAutoFillCancel {
  from {
    /**/
  }

  to {
    /**/
  }
}

.klayo-textfield--autofilled .MuiOutlinedInput-root {
  background-color: rgb(232, 240, 254) !important;
}

.klayo-controllabel,
.klayo-textfield .MuiInputLabel-root,
.klayo_proficiencyselector label,
.klayo-rte label,
.klayo-ewayfield label,
.klayo-radiogroup > label,
.klayo-upload_label,
.klayo-doclist > label {
  height: 20px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--grey-gr-700);
  transform: none !important;
  padding: 0 0 0 14px;
}

.klayo-upload_dropzone {
  margin-top: 4px;
  border-radius: 21px;
  border: 1px solid var(--neon-blue-nb-200);
  padding: 24px;
  cursor: pointer;
}

.klayo-upload_dropzone .klayo-placeholder_text_primary {
  font-weight: 400;
}

.klayo-upload_chipcontainer {
  margin-top: 12px;
}

.klayo-upload .MuiChip-root {
  margin: 12px 12px 0 0;
}

.klayo-upload--error .klayo-upload_dropzone {
  border: 2px solid var(--torch-red-tr-600);
}

.klayo-upload--error .klayo-upload_error {
  font-family: Inter;
  font-size: 12px;
  color: var(--torch-red-tr-600);
  padding: 6px 0 0 14px;
}

.klayo-upload--dragover .klayo-upload_dropzone,
.klayo-upload .klayo-upload_dropzone:hover {
  background-color: #f8f8fe;
}

.klayo-upload--disabled {
  pointer-events: none;
}

.klayo-upload--disabled .klayo-upload_dropzone {
  border-color: var(--grey-gr-300);
  background: var(--grey-gr-100);
}

.klayo-upload--disabled .klayo-placeholder {
  opacity: 0.5;
}

.klayo-selector .MuiTextField-root {
  overflow: hidden;
}

.klayo-textfield .MuiInputAdornment-positionEnd .MuiButtonBase-root,
.klayo-textfield .MuiInputAdornment-positionEnd .MuiInputLabel-root {
  width: 36px !important;
  height: 36px !important;
  padding: 2px !important;
}

.klayo-textfield .MuiInputAdornment-positionEnd .MuiInputLabel-root {
  width: 36px !important;
  height: 36px !important;
}

.klayo-textfield-dense .MuiInputAdornment-positionEnd .MuiButtonBase-root {
  width: 32px !important;
  height: 32px !important;
}

.klayo-textfield-dense .MuiInputAdornment-positionEnd .MuiInputLabel-root {
  width: 32px !important;
  height: 32px !important;
}

.klayo-textfield
  .MuiInputAdornment-positionEnd
  .MuiButtonBase-root.Mui-disabled
  path[fill="#111827"] {
  fill: var(--grey-gr-300) !important;
}

.klayo-selector .MuiOutlinedInput-root {
  max-height: 42px;
  overflow: hidden;
  border-radius: 21px;
}

.klayo-selector .MuiAutocomplete-endAdornment {
  top: 3px;
  transform: unset;
}

.klayo-selector .MuiAutocomplete-endAdornment button {
  width: 36px !important;
  height: 36px !important;
}

.klayo-selector .MuiAutocomplete-clearIndicator,
.klayo-textfield_clearbutton {
  position: absolute;
  z-index: 1;
  background-color: white !important;
  visibility: visible !important;
}

.klayo-selector .MuiAutocomplete-clearIndicator:hover {
  background-color: var(--grey-gr-100) !important;
}

.klayo-selector .MuiOutlinedInput-root {
  flex-direction: column !important;
  flex-flow: nowrap !important;
  justify-content: flex-end !important;
}

.klayo-selector .MuiChip-root {
  max-width: 100px !important;
  margin: 3px 6px 3px 0;
}

.MuiChip-root {
  border-radius: 16px !important;
  border: solid 1px var(--neon-blue-nb-100) !important;
  background-color: var(--white-wh-900) !important;
}

.MuiChip-root .MuiChip-label {
  padding: 0 10px 0 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--grey-gr-900);
}

.MuiChip-root .MuiChip-deleteIcon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.MuiChip-root:hover .MuiChip-deleteIcon {
  background-color: var(--neon-blue-nb-050);
}

.klayo-selector input {
  margin-left: 6px;
  width: auto !important;
  margin-right: 30px !important;
  min-width: 60px !important;
  transition: min-width 200ms ease-in-out;
}

.klayo-selector input:focus {
  min-width: calc(100% - 80px) !important;
}

.klayo-selectmenu {
  height: 42px;
  padding: 9px 13px 9px 13px;
}

.klayo-selectmenu .MuiOutlinedInput-root {
  height: 42px;
}

.klayo-selectmenu-dense {
  height: 40px !important;
}

.klayo-selectmenu-dense .MuiOutlinedInput-root {
  height: 40px !important;
}

.klayo-selectmenu .MuiSelect-select,
.klayo-selectmenu .MuiSelect-select .MuiTypography-root {
  padding: 0px !important;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--grey-gr-900);
}

.klayo-selectmenu_dropbutton {
  position: absolute;
  right: 12px;
  pointer-events: none;
}

.klayo-selectmenu_placeholder {
  font-family: Inter;
  color: var(--grey-gr-500);
  font-size: 16px;
}

.klayo-selectmenu .MuiInputAdornment-positionEnd {
  position: absolute;
  right: 5px;
  margin: 0;
}

.klayo-selectmenu .MuiListItemText-root,
.klayo-selectmenu .MuiTypography-root {
  margin: 0;
  line-height: 22px;
  height: 22px !important;
}

.klayo-selectmenu .MuiListItemIcon-root {
  display: none;
}

.klayo-selectmenu_item:not(.Mui-selected) .MuiListItemIcon-root {
  display: none;
}

.klayo-selectmenu_item .MuiListItemIcon-root path[fill="#111827"] {
  fill: var(--lime-green-lg-600) !important;
}

.klayo-selectmenu-dense .MuiSelect-select {
  padding: 8px 3px !important;
  line-height: 22px;
  height: 22px !important;
}

.klayo-selectmenu-dense .MuiListItemText-primary {
  font-size: 14px !important;
}

.klayo-selectmenu_item-dense li .MuiTypography-root {
  font-size: 14px !important;
}

.klayo-selectmenu fieldset {
  top: -4px;
}

.klayo-table_select {
  margin-left: -10px !important;
  font-size: 14px !important;
}

.klayo-table_select .klayo-selectmenu_value {
  font-size: 16px;
}

.klayo-selectmenu-dense .klayo-selectmenu_value {
  font-size: 14px;
}

.klayo-selectmenu_value {
  width: calc(100% - 26px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.klayo-textfield-withlabel {
  padding-top: 24px !important;
}

.MuiDialogTitle-root,
.klayo-teamfilterpanel h2 {
  font-family: Inter !important;
  font-size: 24px;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: var(--grey-gr-900) !important;
  padding: 40px 40px 32px 40px;
}

.klayo-teamfilterpanel h2 {
  padding: 0 0 32px 0 !important;
  margin: 0 !important;
}

.klayo-teamfilterpanel > .MuiPaper-root {
  padding: 40px 40px 32px 40px !important;
  border-radius: 16px !important;
  box-shadow:
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  background-color: var(--white-wh-900) !important;
  border: none !important;
}

.MuiDialog-paper {
  box-shadow:
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  background-color: var(--white-wh-900) !important;
}

.klayo-userprofilebox_name,
.klayo-userprofilebox_title,
.klayo-userprofilebox_department {
  font-family: Inter;
  font-stretch: normal;
  font-style: normal;
}

.klayo-userprofilebox_name {
  font-size: 24px;
  height: 32px;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: var(--grey-gr-900);
}

.klayo-userprofilebox_title {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--grey-gr-700);
  margin-bottom: 3px;
}

.klayo-userprofilebox_department {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--grey-gr-500);
}

.klayo-userprofiledialog .MuiListItemText-primary {
  height: 24px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--grey-gr-900);
}

.klayo-userprofiledialog .MuiListItemText-secondary {
  height: 24px;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--grey-gr-500);
}

.klayo-userprofiledialog li {
  border-bottom: 1px solid var(--grey-gr-200);
}

.klayo-userprofiledialog li:last-child {
  border-bottom: none;
}

.klaro-chiparray > div {
  margin: 0 13px 13px 0 !important;
}

.klaro-chiparray_clear {
  text-decoration: none !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 32px;
  letter-spacing: normal;
  text-align: center;
  color: var(--neon-blue-nb-500) !important;
  margin-left: 16px;
}

.MuiTooltip-tooltip {
  padding: 11px 10px !important;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  border: solid 1px var(--neon-blue-nb-200) !important;
  background-color: var(--white-wh-900) !important;
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33 !important;
  letter-spacing: normal !important;
  color: var(--grey-gr-900) !important;
}

.MuiTooltip-arrow {
  color: var(--white-wh-900) !important;
}

.MuiTooltip-tooltipPlacementLeft .MuiTooltip-arrow {
  height: 16px !important;
}

.MuiTooltip-arrow::before {
  border: solid 1px var(--neon-blue-nb-200) !important;
}

.klayo-notificationdrawer .MuiToolbar-root {
  min-height: 32px;
}

.klayo-notificationdrawer .MuiToolbar-root button svg {
  fill: var(--neon-blue-nb-500);
}

.klayo-notificationdrawer .MuiTypography-h6 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: var(--grey-gr-900);
  padding: 0;
  margin: 0;
}

.MuiTab-root {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: var(--grey-gr-500) !important;
}

.MuiTab-root.Mui-selected {
  color: var(--neon-blue-nb-500) !important;
}

.klayo-notificationdrawer .MuiTab-root {
  text-transform: none !important;
}

.klayo-notificationdrawer ul {
  width: 100%;
  margin: 0;
  padding: 0px 16px;
  box-sizing: border-box;
}

.klayo-notificationdrawer li {
  padding: 12px 16px;
  border-bottom: 1px solid var(--grey-gr-200);
}

.klayo-notificationdrawer_toolbar,
.klayo-notificationdrawer_tabs {
  box-sizing: border-box;
}

.klayo-statcard_badge {
  width: 64px;
  height: 64px;
  padding: 16px;
  border-radius: 50%;
  background-color: var(--neon-blue-nb-050);
  box-sizing: border-box;
}

.klayo-statcard_badge svg {
  width: 32px;
  height: 32px;
}

.klayo-statcard_title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--grey-gr-500);
}

.klayo-statcard_stat {
  font-family: Inter;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 44px;
  letter-spacing: -1.5px;
  color: var(--grey-gr-900);
}

.MuiLinearProgress-root:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 10px;
}

.klaro_attr-progress_singlerow .klaro_attr-progress_label {
  width: 200px;
  min-width: 200px;
  margin-right: 30px;
  padding: 6px 0px;
}

.MuiAccordionSummary-content .MuiTypography-root {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.2px;
  color: var(--grey-gr-900);
}

.klayo-selector_itempart {
  white-space: pre;
}

.klayo-selector_highlight {
  overflow: visible !important;
  font-weight: 700;
  background-color: var(--neon-blue-nb-050);
  border-radius: 6px;
  padding: 6px 1px;
}

li:hover .klayo-selector_highlight,
li[aria-selected="true"] .klayo-selector_highlight {
  background-color: #e9e9fe59;
}

.klayo-settingsmenu {
}

.klayo-settings_content .MuiPaper-root {
  max-width: 900px;
  margin: 0 auto;
}

.klayo-settingsmenu .MuiMenuItem-root {
  height: 48px;
  line-height: 48px;
}

.klayo-settingsmenu .MuiMenuItem-root {
  color: var(--grey-gr-500);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.1px;
  border-radius: 24px;
}

.klayo-settingsmenu .MuiMenuItem-root.active .MuiListItemText-root {
  color: var(--neon-blue-nb-500) !important;
}

.klayo-settingsmenu .MuiListItemIcon-root {
  min-width: 38px;
}

.klayo-settingsmenu .MuiListItemIcon-root svg {
  width: 24px;
  height: 24px;
}

.klayo-settingsmenu .MuiMenuItem-root svg path[fill="#111827"],
.klayo-settingsmenu .MuiMenuItem-root svg path[fill="#727DF9"],
.klayo-settingsmenu .MuiMenuItem-root svg path[fill="#9EA2FA"] {
  fill: var(--grey-gr-600);
}

.klayo-settingsmenu .MuiMenuItem-root.active .MuiListItemIcon-root svg path[fill="#111827"],
.klayo-settingsmenu .MuiMenuItem-root.active .MuiListItemIcon-root svg path[fill="#727DF9"],
.klayo-settingsmenu .MuiMenuItem-root.active .MuiListItemIcon-root svg path[fill="#9EA2FA"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klayo-settingslanding {
  max-width: 1440px !important;
}

.klayo-settingslanding h1 {
  font-family: Inter;
  font-weight: bold;
  letter-spacing: -1.5px;
  color: var(--grey-gr-900);
}

.klayo-settingslanding p {
  font-family: Inter;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--grey-gr-600);
}

.klayo-settingslanding h3 {
  margin: 40px 0 16px 0;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.2px;
  color: var(--grey-gr-900);
}

.klayo-settingslanding h2 {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.3px;
  color: var(--grey-gr-900);
  margin: 0;
}

.klayo-settingscard {
  height: 100%;
  box-sizing: border-box;
  box-shadow: none !important;
  border: solid 1px var(--neon-blue-nb-050) !important;
  background-color: var(--white-wh-900) !important;
  border-radius: 16px !important;
}

.klayo-settingscard .MuiCardActionArea-focusHighlight {
  display: none;
}

.klayo-settingscard:hover {
  box-shadow:
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  border: solid 1px var(--neon-blue-nb-050) !important;
}

.klayo-settingscard > a:hover .MuiCardContent-root {
  background-color: var(--white-wh-900) !important;
}

.klayo-settingscard_icon,
.klayo-settingscard_icon svg {
  width: 32px;
  height: 32px;
}

.klayo-settingslanding_hidebutton {
  font-size: 14px !important;
}

.klayo-settingscard_icon path[fill="#727DF9"],
.klayo-settingscard_icon path[fill="#111827"] {
  fill: var(--grey-gr-500) !important;
}

.klayo-settingscard .klayo-button svg path[fill="#9CA3AF"] {
  width: 20px;
  height: 20px;
  fill: var(--neon-blue-nb-500) !important;
}

.klayo-instruction {
  color: var(--grey-gr-500) !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 24px;
}

.klayo-instruction-dark {
  color: var(--grey-gr-900) !important;
}

.klayo-switch .MuiSwitch-root {
  padding: 0;
  height: 24px;
}

.klayo-switch .MuiFormControlLabel-root {
  margin-left: 0;
}

.klayo-switch .MuiSwitch-switchBase {
  padding: 0;
  width: 40px;
  background-color: transparent !important;
  height: 24px;
}

.klayo-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--neon-blue-nb-500) !important;
}

.klayo-switch .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
  background-color: var(--grey-gr-200) !important;
}

.klayo-switch .MuiSwitch-thumb {
  width: 16px;
  height: 16px;
  object-fit: contain;
  background-color: var(--white-wh-900);
  box-shadow: none;
  opacity: 1;
  margin-left: -16px;
  pointer-events: none;
}

.klayo-switch_thumb {
  width: 16px;
  height: 16px;
  object-fit: contain;
  background-color: var(--white-wh-900);
  border-radius: 8px;
  margin-left: -16px;
}

.klayo-switch_thumb svg {
  width: 16px;
  height: 16px;
}

.klayo-switch_thumb svg path[fill="#1B3DF2"] {
  fill: var(--grey-gr-400);
}

.klayo-switch .Mui-checked .klayo-switch_thumb {
  margin-left: -24px;
}

.klayo-switch .Mui-checked .klayo-switch_thumb svg path[fill="#1B3DF2"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klayo-switch .MuiSwitch-switchBase.Mui-disabled .klayo-switch_thumb svg path[fill="#1B3DF2"] {
  fill: var(--grey-gr-300) !important;
}

.klayo-switch .MuiSwitch-track {
  width: 40px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--grey-gr-400);
  opacity: 1 !important;
}

.klayo-switch .MuiFormControlLabel-label {
  color: var(--grey-gr-600);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.1px;
  height: 24px;
  line-height: 24px;
}

.MuiDataGrid-columnSeparator {
  color: transparent !important;
}

.MuiDataGrid-columnSeparator:hover {
  color: var(--grey-gr-300) !important;
}

.klayo-multiselector_additem {
  position: absolute !important;
  cursor: pointer;
  bottom: 0;
  margin: 8px 0;
}

.klayo-multiselector_additem .MuiTypography-root {
  color: var(--neon-blue-nb-500) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.klayo-multiselector_additem .MuiListItemIcon-root {
  width: 32px;
  min-width: 32px !important;
}

.klayo-multiselector_additem svg {
  width: 24px;
}

.klayo-multiselector .klayo-selector .MuiChip-root {
  display: none;
}

.klayo-multiselector .MuiChip-root {
  height: 40px;
  border-radius: 20px !important;
}

.klayo-multiselector_dropdownlist_paper--withnewitemoption {
  padding: 0 0 40px 0;
}

.klayo-multiselector_dropdownlist.klayo-multiselector_dropdownlist--withnewitemoption {
  position: unset;
  max-height: 30vh;
}

.klayo-multiselector_dropdownlist--withnewitemoption {
  border-bottom: 1px solid var(--neon-blue-nb-200);
}

.klayo-repeatingselector {
}

.klayo-repeatingselector_row button svg {
  width: 20px;
  height: 20px;
}

.klayo-repeatingselector_row_label {
  line-height: 42px;
  height: 42px;
  color: var(--grey-gr-600);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.1px;
  margin-right: 12px;
}

.klayo-pillbuttons {
  width: auto;
  display: flex;
  flex-direction: column;
}

.klayo-pillbuttons_label {
  height: 20px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--grey-gr-700);
  transform: none !important;
  padding: 0 0 4px 14px;
}

.klayo-pillbuttons .klayo-pillbuttons_tabs {
  height: 42px;
  min-height: 42px !important;
  border-radius: 21px;
  border: 1px solid var(--neon-blue-nb-100);
  width: auto;
  background-color: var(--neon-blue-nb-100);
}

.klayo-pillbuttons .MuiTabs-scroller {
  padding: 0px;
}

.klayo-pillbuttons .MuiTabs-indicator {
  bottom: 0px;
  top: 0;
  z-index: 0;
  height: 42px;
  background-color: var(--neon-blue-nb-100);
}

.klayo-pillbuttons .MuiTab-root {
  z-index: 1;
  height: 42px !important;
  min-height: 42px !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: -0.1px !important;
  text-align: center !important;
  color: var(--grey-gr-600) !important;
  text-transform: none;
  padding: 0;
  background-color: white;
}

.klayo-pillbuttons .MuiTab-root div {
  padding: 8px 18px;
}

.klayo-pillbuttons .MuiTab-root:hover {
  background-color: var(--grey-gr-100);
}

.klayo-pillbuttons .Mui-selected {
  color: var(--grey-gr-900) !important;
  background-color: transparent !important;
}

.klayo-pillbuttons .MuiTab-root:first-child {
  border-radius: 20px 0 0 20px;
  outline: 1px solid var(--neon-blue-nb-100);
}

.klayo-pillbuttons .MuiTab-root:last-child {
  border-radius: 0 20px 20px 0;
  outline: 1px solid var(--neon-blue-nb-100);
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within,
.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

.klayo_jobtable-treecell_group {
}

.klayo_jobtable-treecell_group .Mui-disabled {
  opacity: 0.3;
}

.klayo_jobtable-treecell_employee {
  padding-left: 24px;
}

.klayo_employeetable_adminbadge,
.klayo_employeetable_billingbadge,
.klayo_employeetable_instructorbadge,
.klayo_employeetable_compliantbadge,
.klayo_employeetable_noncompliantbadge {
  height: 20px;
  line-height: 20px;
  padding: 5px 11px;
  border-radius: 16px;
  color: var(--lime-green-lg-900);
  margin-left: 16px;
}

.klayo_employeetable_adminbadge {
  color: var(--lime-green-lg-900);
  background: var(--lime-green-lg-050);
}

.klayo_employeetable_billingbadge {
  color: #0d47a1;
  background: #e3f2fd;
}

.klayo_employeetable_instructorbadge {
  color: #1565c0;
  background: #e3f2fd;
}

.klayo_employeetable_compliantbadge {
  background: var(--lime-green-lg-050);
  color: #007209;
}

.klayo_employeetable_noncompliantbadge {
  background: var(--torch-red-tr-050);
  color: #de001d;
}

.klayo_profilepic-vacancy,
.klayo_profilepic-vacancy .MuiAvatar-root {
  background: var(--neon-blue-nb-050) !important;
}

.klayo_profilepic-vacancy svg path[fill="#111827"] {
  fill: var(--neon-blue-nb-200);
}

.klayo-table .klayo-selectmenu .MuiOutlinedInput-root {
  background-color: transparent !important;
}

.klayo-table .klayo-selectmenu .MuiOutlinedInput-root:not(.Mui-error) fieldset {
  border-color: transparent !important;
  transition: all 400ms ease-in-out;
}

.klayo-table--breakpoint-xs .klayo-table_childattr,
.klayo-table--breakpoint-sm .klayo-table_childattr {
  padding-left: 10px;
}

.klayo-table--breakpoint-md .klayo-table_childattr,
.klayo-table--breakpoint-lg .klayo-table_childattr,
.klayo-table--breakpoint-xl .klayo-table_childattr {
  padding-left: 30px;
}

.klayo-table--tree .klayo-table--breakpoint-xs .klayo-table_parentrow--expanded .MuiDataGrid-cell,
.klayo-table--tree .klayo-table--breakpoint-sm .klayo-table_parentrow--expanded .MuiDataGrid-cell,
.klayo-table--tree
  .klayo-table--breakpoint-xs
  .klayo-table_childrow:not(.klayo-table_childrow--lastchild)
  .MuiDataGrid-cell,
.klayo-table--tree
  .klayo-table--breakpoint-sm
  .klayo-table_childrow:not(.klayo-table_childrow--lastchild)
  .MuiDataGrid-cell {
  border-bottom: none !important;
}

.klayo-table_row--lastrow .MuiDataGrid-cell {
  border-bottom: none !important;
}

.klayo-table--tree .klayo-table--breakpoint-md .klayo-table_parentrow--expanded .MuiDataGrid-cell,
.klayo-table--tree .klayo-table--breakpoint-lg .klayo-table_parentrow--expanded .MuiDataGrid-cell,
.klayo-table--tree .klayo-table--breakpoint-xl .klayo-table_parentrow--expanded .MuiDataGrid-cell,
.klayo-table--tree
  .klayo-table--breakpoint-md
  .klayo-table_childrow:not(.klayo-table_childrow--lastchild)
  .MuiDataGrid-cell,
.klayo-table--tree
  .klayo-table--breakpoint-lg
  .klayo-table_childrow:not(.klayo-table_childrow--lastchild)
  .MuiDataGrid-cell,
.klayo-table--tree
  .klayo-table--breakpoint-xl
  .klayo-table_childrow:not(.klayo-table_childrow--lastchild)
  .MuiDataGrid-cell {
  border-bottom-color: var(--grey-gr-100) !important;
}

.klayo-table_deleteaction {
}

.klayo-table_deleteaction:disabled svg path[fill="#111827"] {
  fill: var(--grey-gr-300) !important;
}

.klayo-table_deleteaction svg path[fill="#111827"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klayo-table_expandbuttoncell {
  padding: 0 !important;
}

.klayo-table--breakpoint-xs .klayo-table_expandbuttoncell,
.klayo-table--breakpoint-sm .klayo-table_expandbuttoncell {
  align-items: flex-start !important;
  padding-top: 12px !important;
}

.klayo-table--breakpoint-xs .klayo-table_expandbuttoncell svg path[fill="#9CA3AF"],
.klayo-table--breakpoint-sm .klayo-table_expandbuttoncell svg path[fill="#9CA3AF"],
.klayo-table--breakpoint-xs .klayo-table_expandbuttoncell svg path[fill="#111827"],
.klayo-table--breakpoint-sm .klayo-table_expandbuttoncell svg path[fill="#111827"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klayo-textfield svg path[fill="#111827"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klaro-chipcontainer {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
}

.klaro-chipcontainer .MuiChip-root {
  box-shadow: none !important;
}

.klaro-chipcontainer_showmore {
  border-bottom: 1px solid var(--grey-gr-300);
  height: 32px;
  text-align: center;
}

.klaro-chipcontainer_showmore button,
.klaro-chipcontainer_showmore button:hover {
  background-color: white !important;
  margin-bottom: -42px;
  font-size: 14px !important;
  color: var(--grey-gr-900);
}

.klaro-chipcontainer_showmore button svg path[fill="#9CA3AF"],
.klaro-chipcontainer_showmore button svg path[fill="#111827"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klaro-chipcontainer .klaro-chip--selected {
  border-color: var(--lime-green-lg-700) !important;
  background-color: var(--lime-green-lg-700) !important;
}

.klaro-chipcontainer .klaro-chip--selected .MuiChip-label {
  color: var(--white-wh-900) !important;
}

.klaro-chipcontainer .klaro-chip svg {
  background-color: transparent !important;
}

.klaro-chipcontainer .klaro-chip svg path[fill="#111827"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klaro-chipcontainer .klaro-chip--selected svg path[fill="#111827"] {
  fill: var(--white-wh-900) !important;
}

.klaro-chipcontainer .klaro-chipcontainer_selectalllink {
  color: var(--neon-blue-nb-500);
  text-decoration: none;
  white-space: nowrap;
}

.klayo-addeditemployee_reportsto .MuiOutlinedInput-root:not(.Mui-disabled),
.klayo-addeditemployee_reportsto .MuiOutlinedInput-root:not(.Mui-disabled) * {
  cursor: pointer;
}

.klayo-tableplaceholder {
  height: 200px;
  z-index: 9;
  /* position: absolute; */
  width: 100%;
  background: white;
}

.klayo-placeholder {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.klayo-placeholder_icon,
.klayo-placeholder_icon svg {
  width: 48px;
  height: 48px;
}

.klayo-placeholder_icon svg path[fill="#111827"],
.klayo-placeholder_icon svg path[fill="#1B3DF2"] {
  fill: var(--neon-blue-nb-200);
}

.klayo-placeholder_text {
  font-family: Inter;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.1px;
  text-align: center;
  color: var(--grey-gr-500);
}

.klayo-placeholder_text_primary {
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -0.3px;
  color: var(--grey-gr-900);
  margin: 0 0 4px 0;
}

.klayo-placeholder_text_secondary {
  font-weight: normal;
  color: var(--grey-gr-500);
}

.klaro_addeattrdialog {
}

.klayo-textfield--loading {
  padding-top: 4px;
}

.klayo-textfield .MuiFormHelperText-root {
  font-family: Inter;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--grey-gr-700);
}

.klayo-table_editlink {
  color: var(--neon-blue-nb-500);
  text-decoration: none;
  font-weight: 500;
}

.klayo-table_requesttitle_attr {
  font-weight: 600;
}

.klayo-table_requesttitle_reason {
  color: var(--grey-gr-500);
}

.klayo-table_requesttitle_requiresdata {
  color: var(--torch-red-tr-500);
  margin-left: 10px;
}

.klayo-attributetable_nonactionablechild {
}

.klayo-attributetable_ghosted {
  color: var(--grey-gr-400);
}

.klay-managerdialog_directreportslist {
  list-style: none;
  padding: 0;
  margin: 0;
}

.klayo-inactiveuserview,
.klayo-errorview,
.klayo-unavailableview {
  margin: 120px auto 0 auto;
  padding: 50px;
  font-family: Inter;
  font-size: 16px;
}

.klayo-errorview {
  max-width: 700px;
}

.klayo-inactiveuserview,
.klayo-unavailableview {
  max-width: 900px;
}

.klayo-inactiveuserview_drawing svg,
.klayo-errorview svg,
.klayo-unavailableview_drawing svg {
  width: 48px;
  height: 48px;
  margin: 20px 20px 20px 0;
}

.klayo-inactiveuserview svg path[fill="#111827"],
.klayo-errorview svg path[fill="#111827"],
.klayo-unavailableview svg path[fill="#111827"] {
  fill: var(--torch-red-tr-300);
}

.klayo-inactiveuserview_drawing svg,
.klayo-unavailableview_drawing svg {
  width: 100%;
  height: 100%;
  max-height: 285px;
}

.klayo-inactiveuserview h1,
.klayo-unavailableview h1 {
  font-family: Inter;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: -1.5px;
}

.klayo-inactiveuserview_message,
.klayo-unavailableview_message {
  font-size: 16px;
  margin: 64px 0;
  line-height: 1.5;
}

.klayo-errorview_message {
  font-size: 16px;
  margin-bottom: 20px;
}

.klayo-errorview_errormore {
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
}

.klayo-errorview a {
  text-decoration: none !important;
}

.klayo-errorview_morelink {
  font-size: 14px;
  cursor: pointer;
  color: var(--grey-gr-400) !important;
}

.klayo-errorview_details {
  font-size: 14px;
  border-top: 1px solid var(--grey-gr-300);
  margin-top: 30px;
  padding-top: 30px;
}

.klaro-jobdescdialog {
  font-family: Inter;
  font-size: 16px;
}

.klayo-rte {
}

.klayo-rte .klayo-rte_box {
  padding: 1px !important;
  background-color: var(--white-wh-900);
  border-radius: 21px;
  border: 1px solid var(--neon-blue-nb-200);
}

.klayo-rte_content {
  padding: 11px 14px !important;
}

.klayo-rte_toolbar {
  border-bottom: 1px solid var(--neon-blue-nb-200);
  padding: 0 0 10px 0;
  display: flex;
  padding: 10px 12px;
}

.klayo-rte_toolbar hr {
  margin: 0 10px 0 4px;
  border-color: var(--neon-blue-nb-100) !important;
}

.klayo-rte_toolbar button {
  margin-right: 6px;
  width: 30px;
  height: 30px;
  padding: 3px;
}

.klayo-rte:hover .klayo-rte_box {
  border: 1px solid var(--neon-blue-nb-300);
}

.klayo-rte--focused .klayo-rte_box {
  padding: 0px !important;
  border: 2px solid var(--neon-blue-nb-500) !important;
}

.klayo-rte--focused label {
  color: var(--neon-blue-nb-400);
}

.klayo-rte_toolbar button svg path[fill="#111827"] {
  fill: var(--neon-blue-nb-200) !important;
}

.klayo-rte--focused .klayo-rte_toolbar button svg path[fill="#111827"] {
  fill: var(--neon-blue-nb-300) !important;
}

.klayo-rte .public-DraftEditor-content {
  min-height: 80px;
  padding-top: 10px;
  font-family: Inter;
  font-size: 16px;
}

.klayo-jobdesccontainer {
  position: relative;
  overflow: hidden;
}

.klayo-jobdesccontainer_text {
  overflow: hidden;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.klayo-jobdesccontainer_text p:first-child {
  margin-top: 0;
}

.klayo-jobdesccontainer_morelink {
  position: absolute;
  bottom: 0;
  right: 0;
}

.klayo-jobdesccontainer_fade {
  position: absolute;
  bottom: 20px;
  background: linear-gradient(0deg, white, #ffffff00);
  left: 0;
  right: 0;
  height: 60px;
}

.klayo-snackbar {
  background-color: var(--white-wh-900) !important;
  border-radius: 16px;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 16px;
}

.klayo-snackbar .klayo-snackbar_icon svg {
  width: 24px;
  height: 24px;
}

.klayo-snackbar .klayo-snackbar_text {
  font-family: Inter;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--grey-gr-900);
  padding: 0 0 0 12px;
}

.klayo-billingplandialog-appbar {
  border-bottom: 1px solid #e9e9fe;
  background-color: #f8f8fe !important;
}

.klayo-billingplandialog .MuiDialogContent-root {
  padding: 40px !important;
}

.klayo-billingplandialog-appbar .klaro-appbar_logo {
  width: 89px;
  height: 32px;
}

.klayo-billingplandialog-appbar .klaro-appbar_logo path {
  fill: var(--neon-blue-nb-400);
}

.klaro_employeeprofile_content {
  text-align: center;
}

.klayo-billingplandialog_content {
  max-width: 640px;
  margin: 0 auto;
}

.klayo-billingplandialog_content h1 {
  align-self: center;
  font-weight: 800;
  letter-spacing: -1.5px;
}

.klayo-billingplandialog_content h2 {
  font-weight: 600;
  margin-bottom: 30px;
}

.klayo-creditcarddetailscard {
  border-radius: 16px !important;
  border: solid 1px var(--neon-blue-nb-050) !important;
  background-color: var(--white-wh-900) !important;
  box-shadow: none !important;
  padding: 24px;
}

.klayo_billingplancard {
  border-radius: 16px !important;
  border: solid 1px var(--neon-blue-nb-050) !important;
  background-color: var(--white-wh-900) !important;
  box-shadow: none !important;
}

.klayo-creditcarddetailscard_icon {
  width: 44px;
  height: 44px;
}

.klayo-creditcarddetailscard_icon path[fill="#111827"] {
  fill: var(--neon-blue-nb-200);
}

.klayo-creditcarddetailscard_name {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--grey-gr-700);
}

.klayo-creditcarddetailscard_details {
  font-family: Inter;
  font-size: 14px;
  line-height: 1.43;
  color: var(--grey-gr-600);
}

.klayo-creditcarddetailscard_expiry {
  margin-left: 10px;
}

.klayo_billingplancard .MuiCardContent-root {
  padding: 20px !important;
}

.klayo_billingplancard--selected {
  outline: 2px solid var(--neon-blue-nb-500) !important;
}

.klayo_billingplancard:not(
    .klayo_billingplancard--selected,
    .klayo_billingplancard-enterprise
  ):hover {
  outline: 2px solid var(--neon-blue-nb-050) !important;
}

.klayo_billingplancard:not(.klayo_billingplancard--selected, .klayo_billingplancard-enterprise) {
  cursor: pointer;
}

.klayo_billingplancard_contents {
  display: flex;
  align-items: center;
}

.klayo_billingplancard_icon {
  width: 64px;
}

.klayo_billingplancard_name {
  font-weight: 700;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.1px;
  color: var(--grey-gr-700);
}

.klayo_billingplancard_price {
  text-align: right;
  width: 140px;
}

.klayo_billingplancard_pricepercycle {
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
}

.klayo_billingplancard_priceperuser {
  font-size: 14px;
}

.klayo_billingplancard_details {
  flex: 1 0 auto;
}

.klayo_billingplancard--disabled {
  pointer-events: none;
  opacity: 0.7;
}

.klayo_billingplancard--disabled svg path[fill="#9EA2FA"] {
  fill: var(--grey-gr-300);
}

.klayo-termsdialog_text {
  font-family: Inter;
  font-size: 16px;
}

.klayo-termsdialog_termslink {
  color: var(--neon-blue-nb-500);
  text-decoration: none;
}

.klayo-layout {
  transition: padding-top 800ms ease-in-out;
}

.klayo-layout--billingbanner {
  padding-top: 44px;
}

.klayo-layout--devmode {
  padding-top: 10px;
}

.klayo-layout--billingbanner.klayo-layout--devmode {
  padding-top: 54px;
}

.klayo-layout--billingbanner .klaro-appbar {
  top: 44px;
}

.klayo-layout--billingbanner .klayo-appdrawer .MuiPaper-root {
  top: 44px;
}

.klayo-layout--devmode .klaro-appbar {
  top: 10px;
}

.klayo-layout--billingbanner.klayo-layout--devmode .klaro-appbar {
  top: 54px;
}

.klayo-billingtable_invoicelink {
  text-decoration: none !important;
  font-weight: 600;
}

.klayo-billingbanner {
  background-color: var(--grey-gr-700) !important;
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0 !important;
  justify-content: center;
  align-items: center;
  height: 32px;
  z-index: 99;
}

.klayo-layout--devmode .klayo-billingbanner {
  top: 10px;
}

.klayo-billingbanner .MuiAlert-message {
  padding: 0 !important;
  overflow: visible;
}

.klayo-billingbanner_message {
  color: white;
  font-family: Inter;
  font-size: 14px;
  line-height: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.klayo-loadingbar {
  top: 0;
  background-color: transparent !important;
}

.klayo-loadingbar .MuiLinearProgress-bar1Indeterminate {
  background-color: var(--bright-turquoise-bt-300);
}

.klayo-loadingbar .MuiLinearProgress-bar2Indeterminate {
  background-color: var(--neon-blue-nb-100);
}

.klayo-billingbanner .klayo-billingbanner_actions button {
  background-color: #3dbd35 !important;
  height: 28px;
  font-size: 14px !important;
  padding: 4px 12px !important;
}

.klaro-switchuserdialog_table {
  border-top: 1px solid var(--grey-gr-300);
  border-bottom: 1px solid var(--grey-gr-300);
}

.klaro-switchuserdialog_search,
.klaro-switchuserdialog_orgselect {
  font-size: 14px;
}

.klaro_attr-progressoverrview {
  display: flex;
  padding: 6px 0;
}

.klaro_attr-progressoverrview_bars {
  flex-grow: 1;
}

.klaro_attr-progressoverrview_labels {
  transition: width 200ms ease-in-out;
}

.klaro_attr-progressoverrview_bars > div,
.klaro_attr-progressoverrview_labels > div {
  display: flex;
  line-height: 20px;
  align-items: center;
}

.klaro_attr-progressoverrview_bars > div {
  min-height: 20px;
}

.klaro_attr-progressoverrview_labels > div {
  margin-right: 30px;
}

.klaro_attr-progressoverrview_bars > div:first-child,
.klaro_attr-progressoverrview_labels > div:first-child {
  margin-top: 0;
}

.klayo-filterselector_option .MuiListItemSecondaryAction-root {
  color: var(--grey-gr-400);
  font-size: 14px;
}

.klayo-filterselector_option:hover .MuiListItemSecondaryAction-root {
  color: var(--white-wh-900);
}

.klayo-filterselector_option--greyed .MuiListItemText-primary {
  color: var(--grey-gr-300) !important;
}

.klayo-billingselectioncard {
  background-color: transparent !important;
  font-size: 16px;
  border: 1px solid var(--neon-blue-nb-100) !important;
  padding: 32px 20px !important;
}

.klayo-billingselectioncard_changelink {
  text-decoration: none !important;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}

.klayo-alert {
  border-radius: 24px !important;
  width: 100%;
}

.klayo-alert_container {
  display: flex;
}

.klayo-alert .MuiAlert-message {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--grey-gr-700);
}

.klayo-confirmationbox {
  margin: auto auto;
  width: 300px;
  text-align: center;
}

.klayo-confirmationbox_icon svg {
  width: 80px;
  height: 80px;
}

.klayo-confirmationbox_icon svg path[fill="#111827"] {
  fill: var(--neon-blue-nb-200);
}

.klayo-confirmationbox_heading {
  font-family: Inter;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -1px;
  color: var(--grey-gr-900);
}

.klayo-confirmationbox_message {
  font-family: Inter;
  font-size: 16px;
  letter-spacing: -0.1px;
  color: var(--grey-gr-500);
}

.klayo-text-md {
  font-size: 16px;
}

.klayo-billingplandialog_content .klayo-pillbuttons .MuiTab-root div {
  padding: 8px 8px;
}

.klayo-tooltipicon svg path[fill="#1E88E5"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klayo-devbar {
  position: fixed;
  border-top: 10px dashed var(--torch-red-tr-200);
  box-sizing: border-box;
  background: var(--torch-red-tr-100);
  z-index: 8999;
  left: 0;
  right: 0;
  top: 0;
}

.klayo-radiogroup {
}

.klayo-radiogroup > label {
  margin-bottom: 4px;
}

.klayo-radiogroup_item {
  border-radius: 16px;
  border: 1px solid var(--neon-blue-nb-050);
}

.klayo-radiogroup_item .MuiRadio-root {
  color: var(--neon-blue-nb-200) !important;
}

.klayo-radiogroup_item svg path[fill="#3DBD35"] {
  fill: var(--neon-blue-nb-500);
}

.klayo-radiogroup_item label {
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  letter-spacing: -0.1px !important;
  width: 100%;
  padding: 6px 16px;
  color: var(--grey-gr-900);
}

.klayo-radiogroup_item label div {
  margin-left: 4px;
  padding: 8px 0;
}

.klayo-radiogroup_item--selected {
  border: 2px solid var(--neon-blue-nb-500);
}

.klayo-radiogroup_item--selected label {
  padding: 5px 15px;
}

.klayo-radiogroup_item--disabled {
  border: 1px solid var(--grey-gr-300);
  background-color: var(--grey-gr-100);
}

.klayo-radiogroup_item--disabled label {
  color: var(--grey-gr-500);
}

.klayo-radiogroup_item--disabled .MuiRadio-root {
  color: var(--grey-gr-400) !important;
}

.klayo-radiogroup_item .MuiFormHelperText-root {
  margin: 0;
  letter-spacing: normal;
}

.stn-wdgt div[open="true"] {
  min-width: 100px;
  min-height: 32px;
}

/*.stn-wdgt>div>div[open="true"]::after {
    content: '';
    position: absolute;
    background: white;
    width: 32px;
    height: 32px;
    bottom: 7px;
    right: 8px;
}*/

.stn-wdgt iframe {
  border-bottom-right-radius: 111px !important;
}

.klayo-appdrawer .MuiPaper-root {
  background-color: white;
}

.klayo-appdrawer.MuiDrawer-modal .klayo-appdrawer_logo {
  display: none;
}

.klayo-ewayfield {
}

.klayo-ewayfield-ewayfield {
  background-color: white;
  border-radius: 21px;
  height: 42px;
}

.klayo-ewayfield--focus .klayo-ewayfield-ewayfield {
  border-width: 2px;
}

.klayo-ewayfield-expiry .klayo-ewayfield-ewayfield {
  background-color: transparent;
}

.klayo-appdrawer {
  bottom: 0px;
}

.klaro-appdrawer-menu {
}

.klayo-appdrawer_childlist {
  padding: 0 !important;
}

.klayo-appdrawer-menuwrapper {
  padding: 0 8px;
  flex-grow: 1;
  overflow-y: auto;
}

.klayo-appdrawer-menuwrapper .MuiDivider-root {
  margin: 8px 8px;
}

.klaro-appdrawer_menuitem {
  min-height: 48px;
  border-radius: 24px !important;
  padding: 12px;
}

.klaro-appdrawer_menuitem:hover {
  background-color: var(--grey-gr-050);
}

.klaro-appdrawer_childmenuitem {
  padding: 12px 12px 12px 24px !important;
}

.klaro-appdrawer_menuitem .MuiListItemText-root {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-decoration: none;
  color: var(--grey-gr-500);
}

.klaro-appdrawer_menuitem.active {
  background-color: var(--neon-blue-nb-025);
}

.klaro-appdrawer_menuitem.active .MuiListItemText-root {
  color: var(--neon-blue-nb-500);
}

.klaro-appdrawer_parentmenuitem.active {
  background-color: transparent !important;
}

.klaro-appdrawer_parentmenuitem.active .MuiListItemText-root {
  color: var(--grey-gr-500) !important;
}

.klaro-appdrawer_menuitem .MuiListItemIcon-root {
  width: 40px;
}

.klaro-appdrawer_menuitem--hidden {
  display: none !important;
}

.klaro-appdrawer_menuitem .MuiListItemIcon-root {
  min-width: 40px;
}

.klaro-appdrawer_menuitem svg {
  width: 24px;
  height: 24px;
}

.klaro-appdrawer_usermenuitem {
  border-top: 1px solid var(--neon-blue-nb-050);
  height: 64px;
  cursor: pointer;
}

.klaro-appdrawer_usermenuitem:hover {
  background-color: var(--grey-gr-050);
}

.klaro-appdrawer_usermenuitem .MuiListItemIcon-root {
  min-width: 32px !important;
  margin-right: 8px;
}

.klaro-appdrawer_usermenuitem .MuiListItemText-primary {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-gr-700);
}

.klaro-appdrawer_usermenuitem .MuiListItemText-secondary {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-gr-500);
}

.klaro-appdrawer_menuitem svg path[fill="#111827"],
.klaro-appdrawer_menuitem svg path[fill="#727DF9"],
.klayo-appdrawer_settingsdrawer .MuiToolbar-root button svg path[fill="#727DF9"] {
  fill: var(--neon-blue-nb-500) !important;
}

.klaro-appdrawer_menuitem_collapsebutton svg path[fill="#111827"],
.klaro-appdrawer_menuitem_collapsebutton svg path[fill="#9CA3AF"] {
  fill: var(--grey-gr-500) !important;
}

.klayo-appdrawer_closebutton {
}

.klayo-appdrawer_settingsdrawer .MuiToolbar-root {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.1px;
  height: 56px;
}

.klayo-appdrawer_settingsdrawer .MuiToolbar-root button {
  margin: 0 8px 0 0px;
}

.klayo-drawer {
  max-width: 100vw;
}

.klayo-drawer .MuiPaper-root {
  max-width: 100vw;
}

.klayo-notificationdrawer_title {
  font-family: Inter;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: var(--grey-gr-900);
}

.klayo-table_headerwrap {
  width: 100%;
  white-space: normal;
  line-height: 15px;
  text-align: right;
}

.klayo-table_multilinerow {
  width: 100%;
}

.klayo-attrtable_multilinerow_row1,
.klayo-attrtable_multilinerow_row2,
.klayo-attrtable_multilinerow_row3 {
  line-height: 24px;
}

.klayo-attrtable_multilinerow_row1 {
  font-size: 16px;
  font-weight: 500;
}

.klayo-attrtable_multilinerow_row2 {
  color: var(--grey-gr-500);
}

.klayo-attrtable_multilinerow_row3 {
}

.klayo-attrtable_attachment path[fill="#1B3DF2"] {
  fill: var(--grey-gr-400);
}

.klayo-employeetable_multilinerow_row1,
.klayo-employeetable_multilinerow_row2,
.klayo-employeetable_multilinerow_row3,
.klayo-employeetable_multilinerow_row4 {
  line-height: 24px;
  min-height: 24px;
}

.klayo-employeetable_multilinerow_row1 {
  font-size: 16px;
  font-weight: 500;
}

.klayo-employeetable_multilinerow_row2 {
  color: var(--grey-gr-500);
  font-size: 16px;
}

.klayo-employeetable_multilinerow_row3 {
  font-size: 16px;
}

.klayo-employeetable_multilinerow_row4 {
  display: flex;
  align-items: end;
}

.klayo-breakpointindicator {
  position: fixed;
  height: 20px;
  background: var(--bright-turquoise-bt-300);
  min-width: 120px;
  left: calc(50% - 60px);
  bottom: 16px;
  z-index: 9999;
  display: flex;
  place-content: space-evenly;
  border-radius: 10px;
  overflow: hidden;
  pointer-events: none;
}

.klayo-breakpointindicator_item {
  z-index: 1;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 20px;
  color: #ffffff;
  font-weight: 300;
}

.klayo-breakpointindicator_indicator {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(45deg, #ffffff00, #ffffff73);
  transition: width 800ms ease-in-out;
}

.klaro-appmenu-settingsmenu {
  background-color: blue;
}

.klayo-appbar_button:active {
}

.MuiTablePagination-displayedRows {
  font-family: Inter !important;
}

.klayo-button--hidelabel {
  background-color: red;
}

.klayo-feedbacktable_summarycol_date {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--grey-gr-500);
}

.klayo-feedbacktable_summarycol_name {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--grey-gr-900);
}

.klayo-feedbacktable_summarycol_attribute {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--grey-gr-500);
}

.klayo-feedbacktable_summarycol_action {
  font-family: Inter;
  font-size: 16px;
  font-stretch: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
}

.klayo-feedbacktable_summarycol_action a {
  font-weight: 500;
  font-size: 16px;
}

.klayo-optionmenu h2 {
  margin: 0 0 16px 16px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -0.3px;
  color: var(--grey-gr-900);
}

.MuiAvatarGroup-root > .MuiAvatar-root:first-child {
  z-index: 999;
  background-color: var(--neon-blue-nb-500);
  color: var(--white-wh-900);
}

.klayo-button-group .klayo-iconbutton.Mui-disabled {
  background: white;
}

.klayo-button-group .klayo-iconbutton.Mui-disabled svg path {
  fill: var(--grey-gr-400) !important;
}

.MuiStepLabel-label {
  font-family: "Inter" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: -0.1px !important;
}

.MuiStepLabel-label {
  color: var(--grey-gr-400) !important;
}

.MuiStepLabel-label.Mui-active,
.MuiStepLabel-label.Mui-completed {
  color: var(--grey-gr-900) !important;
}

.MuiStepIcon-root.Mui-active,
.MuiStepIcon-root.Mui-completed {
  color: var(--neon-blue-nb-500) !important;
}

.MuiStepIcon-root {
  color: var(--grey-gr-400) !important;
}

.MuiStepIcon-text {
  font-family: "Inter" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  transform: translate(0px, -1px);
}

.klayo_inlinedoclist_deleted {
  opacity: 0.5;
}

.klayo_inlinedoclist a {
  cursor: pointer;
}

.klayo-feeditem .MuiListItem-root {
  padding: 16px;
}

.klayo-feeditem_content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--grey-gr-900);
  padding-top: 6px;
  line-height: 24px;
}

.klayo-feeditem_date {
  font-family: "Inter" !important;
  color: var(--grey-gr-500) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: -0.1px;
}

.klayo-feeditem_user {
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin-bottom: 8px;
}

.klayo-feeditem_status,
.klayo-feeditem_comment {
  letter-spacing: -0.1px;
}

.klayo-feeditem_documents {
  padding-top: 12px;
  display: block;
}

.klayo-feed .klayo-feed_expandicon path {
  fill: var(--neon-blue-nb-500);
}

.klayo-doclist .MuiDataGrid-cell {
  padding: 0 14px !important;
}

.Klayo_recurrence-cell.MuiDataGrid-cell {
  padding-right: 0px !important;
  padding-left: 20px !important;
}

.Klayo_recurrence-menu-list .MuiListItemIcon-root {
  margin-left: 16px;
  justify-content: center;
  min-width: 24px !important;
}

.klayo__training-header-tooltip {
  margin: 14px !important;
  bottom: -12px;
}

.klayo__attribute-certification-tooltip {
  margin: 14px !important;
  left: 2px;
}

.klayo__integration-status-tooltip {
  margin: 14px !important;
  bottom: 0px;
  left: 4px;
  max-width: 240px !important;
}

.MuiPickersPopper-root .MuiPaper-root {
  border-radius: 16px;
  border: 1px solid var(--neon-blue-nb-200);
}
.MuiPickersPopper-root .MuiPickersDay-root.Mui-selected {
  background-color: var(--neon-blue-nb-500) !important;
}

.MuiPickersPopper-root .MuiPickersDay-root.MuiPickersDay-today {
  border: 1px solid var(--neon-blue-nb-200) !important;
}

.klayo_time-picker-popper .MuiDialogActions-root {
  display: none;
}

.klayo_time-picker-popper .MuiMultiSectionDigitalClock-root {
  padding: 16px 24px 0px 24px;
  width: 175px;
}

.klayo_time-picker-popper .MuiMultiSectionDigitalClockSection-root:not(:first-of-type) {
  border-left: none;
}

.klayo_time-picker-popper .MuiMultiSectionDigitalClockSection-item {
  padding: 4px 8px;
  border-radius: 8px;
  margin-left: 0px;
}

.klayo_time-picker-popper .MuiMultiSectionDigitalClockSection-item.Mui-selected:hover {
  background: var(--neon-blue-nb-400);
}

.klayo_time-picker-popper .MuiMultiSectionDigitalClockSection-root {
  width: 58px;
}

.klayo_time-picker-popper .MuiMultiSectionDigitalClockSection-root:hover {
  overflow-y: overlay;
  cursor: pointer !important;
}

.klayo_time-picker-popper .MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
  width: 10px;
}

.klayo_time-picker-popper .MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar-track {
  background: transparent;
}

.klayo_time-picker-popper .MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar-thumb {
  background: #888;
}

.klayo_time-picker-popper .MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar-thumb:hover {
  background: var(--neon-blue-nb-200);
}
.klayo_practical-helper-box {
  position: absolute;
  top: 32px;
  right: 14px;
  cursor: pointer;
}
.klayo_practical-helper-box-disabled {
  cursor: not-allowed;
}
.klayo_practical-helper-text {
  font-family: Inter !important;
  color: var(--neon-blue-nb-600);
  font-size: 14px !important;
  font-weight: 500 !important;
}
.klayo_practical-helper-text-disabled {
  color: var(--grey-gr-500);
}

.disabled-container {
  /* opacity: 0.5; */
  pointer-events: none;
  background-color: var(--grey-gr-200) !important;
  color: var(--grey-gr-400) !important;
}
.klaro_regulations-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(29, 59, 242);
  border-radius: 20px;
}

.klaro_regulations-vertical-line {
  border-left: 1px solid #f8f8fe;
  height: 30px;
}

.klaro_regulations-domain-category {
  padding: 6px 0;
  border-radius: 20px;
}

.klaro_regulations-domain-category:hover {
  background-color: #0017cf;
  border-top-left-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
  cursor: pointer;
}

.klaro_regulations-button-add-regulation {
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

.klaro_regulations-button-add-regulation:hover {
  background-color: #0017cf !important;
}
.klaro_regulations-button-add-regulation:focus::before {
  border: none !important;
}

.klaro_regulations-display-none {
  display: none !important;
}

.klaro_regulations-display-block {
  display: block !important;
}

.klayo-confirmation-question-text {
  line-height: 1.5;
}

.klayo-table_rowexpanded {
  font-weight: bold;
}

.klayo-multiselector_dropdownlist.group-by {
  max-height: 50vh;
}

.klayo-multiselector_dropdownlist.group-by > li {
  height: fit-content;
  background-color: transparent !important;
  padding: 0;
}

.klayo-multiselector_dropdownlist.group-by > li:not(:first-child) {
  height: fit-content;
  background-color: transparent !important;
  padding: 0;
  margin-top: 12px;
}

.klayo-multiselector_dropdownlist.group-by > li:hover {
  background-color: transparent !important;
}

.klayo-multiselector_dropdownlist.group-by > li > .MuiListSubheader-root {
  line-height: 1;
  padding: 8px 16px;
  font-weight: 500;
  color: var(--grey-gr-400);
  font-family: Inter;
}

.klayo-multiselector_dropdownlist.group-by > li .MuiListItem-root {
  padding: 8px 16px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-full {
  width: 100%;
}
